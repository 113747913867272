import styled from 'styled-components';

export const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  outline: none;
  -webkit-appearance: none;

  &::placeholder {
    font-size: 0.8rem;
  }
`;
