import styled, { css } from 'styled-components';

const commonPageWrapperStyles = css`
  display: flex;
  width: 100%;
  padding: 0.5rem 0.5rem 1rem;
  flex-direction: column;
  align-items: center;
`;

export const HomePageWrapper = styled.div`
  ${commonPageWrapperStyles}
`;

export const ResultPageWrapper = styled.div`
  ${commonPageWrapperStyles}
  justify-content: center;
`;

export const Text = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

export const ArtTitle = styled.p`
  margin: 0 0 1.5rem 0;
  color: #006cab;
  font-size: 2.5rem;
  font-weight: bold;
`;

export const ResultText = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  color: #006cab;
  font-size: 1.2rem;
  font-weight: 700;
`;
