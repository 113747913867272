import React from 'react';

import { CheckboxWrapper, CheckboxInput, CheckboxLabel } from './styled';

type CheckboxProps = {
  label: string;
  id?: string;
  onChange: (e: React.SyntheticEvent) => void;
};

const Checkbox = ({ label, id = 'checkbox', onChange }: CheckboxProps) => (
  <CheckboxWrapper>
    <CheckboxInput type="checkbox" id={id} onChange={onChange} />
    <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>
  </CheckboxWrapper>
);

export default Checkbox;
