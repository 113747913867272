import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';

import {
  Image,
  Checkbox,
  Textarea,
  Button,
  PoweredBy,
  Spinner,
} from '@/components';
import Result from './result';
import { useApi } from '@/hooks';
import { HomePageWrapper, Text, ArtTitle } from './styled';
import { getUrlParams } from '@/helpers';
import { urls } from '@/urls';

import logo from '@/assets/images/logo.jpg';

type HomeProps = {
  entry: {
    tagId: string;
    entryId: string;
  };
  isAdminRegistered: boolean;
};

const Home = ({ entry, isAdminRegistered }: HomeProps) => {
  const { queryMap } = getUrlParams();
  const [selected, setSelected] = useState(false);
  const [message, setMessage] = useState('');
  const {
    response: voteResponse,
    loading: voteLoading,
    makeRequest: vote,
  } = useApi({
    url: urls.vote,
    method: 'POST',
  });

  if (isAdminRegistered) {
    return <Result isAdminRegistered={isAdminRegistered} />;
  }

  if (voteResponse) {
    return <Result />;
  }

  const onCheck = (e: React.SyntheticEvent) => {
    const { checked } = e.target as HTMLInputElement;
    setSelected(checked);
  };

  const onMessage = (e: React.SyntheticEvent) => {
    const { value } = e.target as HTMLTextAreaElement;
    setMessage(value);
  };

  return (
    <HomePageWrapper>
      <ToastContainer />
      <Spinner isShown={voteLoading} />
      <div style={{ marginBottom: '1rem' }}>
        <Image src={logo} width="82px" height="128px" />
      </div>
      <Text>Thanks for participating in the voting</Text>
      <Text>for the Holiday Card Project.</Text>
      <ArtTitle>Entry #{entry.entryId}</ArtTitle>
      <Text>You can vote for up to 3 entries.</Text>
      <Checkbox label="Vote for this entry" onChange={onCheck} />
      <div
        style={{
          width: '90%',
          margin: '1rem 0',
        }}
      >
        <Textarea
          rows={5}
          placeholder="Leave message here for young artists..."
          onChange={onMessage}
        />
      </div>
      <Button
        onClick={vote({
          message,
          deviceId: queryMap.deviceId,
          tagId: queryMap.tapWowTagId,
          isVoted: selected,
        })}
      >
        Submit
      </Button>
      <PoweredBy />
    </HomePageWrapper>
  );
};

export default Home;
