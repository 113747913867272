import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import App from '@/App';
import { urls } from '@/urls';
import { getUrlParams } from '@/helpers';

import 'react-toastify/dist/ReactToastify.css';
import '@/assets/index.css';

const init = async () => {
  try {
    let entry = { tagId: '', entryId: '' };
    let isAdminRegistered = false;

    const {
      queryMap: { tapWowTagId, deviceId },
    } = getUrlParams();
    const { REACT_APP_ADMIN_TAG_ID } = process.env;

    if (tapWowTagId === REACT_APP_ADMIN_TAG_ID) {
      await axios.post(urls.createAdmin, { deviceId });
      isAdminRegistered = true;
    } else {
      const { data } = await axios.get(urls.entryById(tapWowTagId));
      entry = data;
    }

    ReactDOM.render(
      <React.StrictMode>
        <App entry={entry} isAdminRegistered={isAdminRegistered} />
      </React.StrictMode>,
      document.getElementById('root')
    );
  } catch (e) {
    console.log('Initialisation error =>', e);
  }
};

init();
