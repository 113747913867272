import React, { useEffect } from 'react';

import Home from '@/pages/index';

type AppProps = {
  entry: {
    tagId: string;
    entryId: string;
  };
  isAdminRegistered: boolean;
};

const App = ({ entry, isAdminRegistered }: AppProps) => {
  useEffect(() => {
    const preloader = document.getElementById('preloader');
    preloader?.remove();
  }, []);

  return <Home entry={entry} isAdminRegistered={isAdminRegistered} />;
};

export default App;
