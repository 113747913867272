type UrlsType = {
  vote: string;
  createAdmin: string;
  entryById: (id: string) => string;
};

const { REACT_APP_SERVER_HOST } = process.env;

export const urls: UrlsType = {
  vote: `${REACT_APP_SERVER_HOST}/vote`,
  createAdmin: `${REACT_APP_SERVER_HOST}/admin`,
  entryById: (id: string) => `${REACT_APP_SERVER_HOST}/entry/${id}`,
};
