import React from 'react';

type ImageProps = {
  src: string;
  width: string;
  height: string;
};

const Image = ({ src, width, height }: ImageProps) => (
  <img src={src} width={width} height={height} alt="#" />
);

export default Image;
