import React from 'react';

import TapwowLogo from '../tapwow-logo';

import { Wrapper, Title } from './styled';

const PoweredBy = () => (
  <Wrapper>
    <Title>Powered by</Title>
    <TapwowLogo />
  </Wrapper>
);

export default PoweredBy;
