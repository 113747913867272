import React from 'react';

import { SpinnerWrapper, SpinnerItemsWrapper } from './styled';

type SpinnerProps = {
  isShown: boolean;
};

const Spinner = ({ isShown }: SpinnerProps) => {
  if (!isShown) return null;

  return (
    <SpinnerWrapper>
      <SpinnerItemsWrapper>
        {Array(8)
          .fill(() => <div />)
          .map((Div, i) => (
            <Div key={i} />
          ))}
      </SpinnerItemsWrapper>
    </SpinnerWrapper>
  );
};

export default Spinner;
