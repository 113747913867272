import React from 'react';

import { StyledButton } from './styled';

type ButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: (e: React.SyntheticEvent) => any;
};

const Button = ({ children, disabled = false, onClick }: ButtonProps) => {
  return (
    <StyledButton type="button" disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default Button;
