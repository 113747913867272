import React from 'react';

const TapwowLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="153.584"
    height="21.681"
    viewBox="0 0 153.584 21.681"
  >
    <g id="Group_27" data-name="Group 27" transform="translate(0 0)">
      <path
        id="Path_555"
        data-name="Path 555"
        d="M768.519,1019.91a.686.686,0,0,1,.136-.015.488.488,0,0,1,.12.015h8.105a1.409,1.409,0,0,1,1.429,1.409,1.436,1.436,0,0,1-1.429,1.424h-6.8v17.116a1.385,1.385,0,0,1-.421,1.019,1.4,1.4,0,0,1-1.007.4,1.419,1.419,0,0,1-1.022-.4,1.385,1.385,0,0,1-.421-1.019v-17.116h-6.8a1.376,1.376,0,0,1-1.008-.42,1.336,1.336,0,0,1-.421-1,1.368,1.368,0,0,1,.421-1,1.4,1.4,0,0,1,1.008-.4Z"
        transform="translate(-758.985 -1019.893)"
        fill="#5933AA"
      />
      <path
        id="Path_556"
        data-name="Path 556"
        d="M885.911,1034.509H872.316v5.351a1.386,1.386,0,0,1-.421,1.019,1.369,1.369,0,0,1-1.008.4,1.4,1.4,0,0,1-1.007-.4,1.387,1.387,0,0,1-.421-1.019v-10.342a9.689,9.689,0,0,1,9.655-9.622,9.327,9.327,0,0,1,6.827,2.833,9.214,9.214,0,0,1,2.842,6.789v10.342a1.386,1.386,0,0,1-.421,1.019,1.42,1.42,0,0,1-1.022.4,1.4,1.4,0,0,1-1.008-.4,1.387,1.387,0,0,1-.421-1.019Zm-13.595-2.848h13.595v-2.143a6.8,6.8,0,0,0-13.595,0Z"
        transform="translate(-848.53 -1019.893)"
        fill="#5933AA"
      />
      <path
        id="Path_557"
        data-name="Path 557"
        d="M999.531,1028.3a8.083,8.083,0,0,1,2.466-5.946,8.484,8.484,0,0,1,11.941,0,8.383,8.383,0,0,1,0,11.877,8.181,8.181,0,0,1-5.97,2.456H1002.4v3.176a1.366,1.366,0,0,1-.421,1,1.394,1.394,0,0,1-1.022.419,1.435,1.435,0,0,1-1.429-1.423Zm2.872,5.542h5.564a5.384,5.384,0,0,0,3.94-1.632,5.534,5.534,0,0,0,0-7.833,5.564,5.564,0,0,0-9.5,3.924Z"
        transform="translate(-953.96 -1019.893)"
        fill="#5933AA"
      />
      <path
        id="Path_558"
        data-name="Path 558"
        d="M1116.735,1021.319a1.437,1.437,0,0,1,1.429-1.424,1.346,1.346,0,0,1,1.008.42,1.367,1.367,0,0,1,.421,1v12.59a4.537,4.537,0,0,0,4.541,4.527,4.418,4.418,0,0,0,3.218-1.319,4.338,4.338,0,0,0,1.324-3.192v-12.6a1.436,1.436,0,0,1,1.429-1.424,1.394,1.394,0,0,1,1.023.42,1.37,1.37,0,0,1,.421,1v12.6a4.338,4.338,0,0,0,1.323,3.192,4.549,4.549,0,0,0,7.76-3.207v-12.59a1.41,1.41,0,0,1,1.429-1.409,1.4,1.4,0,0,1,1.008.4,1.368,1.368,0,0,1,.421,1v12.59a7.094,7.094,0,0,1-2.166,5.216,7.425,7.425,0,0,1-10.467,0,6.851,6.851,0,0,1-.752-.854,6.041,6.041,0,0,1-.737.854,7.4,7.4,0,0,1-12.633-5.216Z"
        transform="translate(-1048.96 -1019.893)"
        fill="#5933AA"
      />
      <path
        id="Path_559"
        data-name="Path 559"
        d="M1428.457,1021.319a1.436,1.436,0,0,1,1.429-1.424,1.345,1.345,0,0,1,1.007.42,1.368,1.368,0,0,1,.421,1v12.59a4.537,4.537,0,0,0,4.542,4.527,4.42,4.42,0,0,0,3.218-1.319,4.341,4.341,0,0,0,1.323-3.192v-12.6a1.436,1.436,0,0,1,1.428-1.424,1.4,1.4,0,0,1,1.023.42,1.369,1.369,0,0,1,.421,1v12.6a4.339,4.339,0,0,0,1.323,3.192,4.549,4.549,0,0,0,7.76-3.207v-12.59a1.41,1.41,0,0,1,1.429-1.409,1.4,1.4,0,0,1,1.007.4,1.367,1.367,0,0,1,.421,1v12.59a7.094,7.094,0,0,1-2.166,5.216,7.425,7.425,0,0,1-10.467,0,6.835,6.835,0,0,1-.752-.854,5.964,5.964,0,0,1-.737.854,7.4,7.4,0,0,1-12.632-5.216Z"
        transform="translate(-1301.627 -1019.893)"
        fill="#5933AA"
      />
      <g id="Group_26" data-name="Group 26" transform="translate(99.709)">
        <path
          id="Path_560"
          data-name="Path 560"
          d="M1294.164,1021.91a11.982,11.982,0,0,1,9.419,19.377,13.067,13.067,0,0,0,1.067-.955,11.975,11.975,0,1,0-16.935-16.935,13.064,13.064,0,0,0-.955,1.067A11.47,11.47,0,0,1,1294.164,1021.91Z"
          transform="translate(-1286.483 -1019.885)"
          fill="#5933AA"
        />
        <path
          id="Path_561"
          data-name="Path 561"
          d="M1291.307,1044.692a9.661,9.661,0,0,1,7.6,15.624,10.518,10.518,0,0,0,.861-.77,9.656,9.656,0,1,0-13.656-13.655,10.587,10.587,0,0,0-.77.861A9.252,9.252,0,0,1,1291.307,1044.692Z"
          transform="translate(-1285.33 -1038.669)"
          fill="#5933AA"
        />
        <path
          id="Path_562"
          data-name="Path 562"
          d="M1289.683,1068.557a7.1,7.1,0,0,1,5.584,11.486,7.962,7.962,0,0,0,.633-.566,7.1,7.1,0,1,0-10.039-10.039,8.016,8.016,0,0,0-.566.633A6.8,6.8,0,0,1,1289.683,1068.557Z"
          transform="translate(-1285.295 -1058.363)"
          fill="#5933AA"
        />
        <g
          id="Group_25"
          data-name="Group 25"
          transform="translate(0.205 13.62)"
        >
          <path
            id="Path_563"
            data-name="Path 563"
            d="M1294.216,1095.7a3.919,3.919,0,1,0-1.15,2.773A3.773,3.773,0,0,0,1294.216,1095.7Z"
            transform="translate(-1286.377 -1091.78)"
            fill="#5933AA"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default TapwowLogo;
