import React from 'react';

import { ResultPageWrapper, ResultText } from './styled';

type ResultProps = {
  isAdminRegistered?: boolean;
};

const Result = ({ isAdminRegistered = false }: ResultProps) => (
  <ResultPageWrapper>
    {!isAdminRegistered ? (
      <>
        <ResultText>Thank you for voting!</ResultText>
        <ResultText>Remember you can only vote</ResultText>
        <ResultText>for 3 entries.</ResultText>
      </>
    ) : (
      <ResultText>Admin device was successfully registered!</ResultText>
    )}
  </ResultPageWrapper>
);

export default Result;
