import styled from 'styled-components';

export const StyledButton = styled.button`
  padding: 0.8rem 1.5rem;
  color: #ffffff;
  font-weight: bold;
  background-color: #e71537;
  border: none;
  outline: 0;
  opacity: 1;
  transition: opacity 0.2s;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
