import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked + label::before {
    background-color: #e71537;
  }

  &:checked + label::after {
    border-color: #ffffff;
    border-left-width: 2px;
    border-bottom-width: 2px;
    opacity: 1;
  }
`;

export const CheckboxLabel = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  color: #e71537;
  user-select: none;

  &::before {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border: 1px solid #e71537;
    border-radius: 0.25rem;
    transition: background-color 0.2s;
    content: '';
  }

  &::after {
    position: absolute;
    left: 0.2rem;
    top: 0.35rem;
    width: 0.6rem;
    height: 0.4rem;
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transform: rotate(-50deg);
    opacity: 0;
    transition: opacity 0.2s;
    content: '';
  }
`;
