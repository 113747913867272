import React from 'react';

import { StyledTextarea } from './styled';

type TextareaProps = {
  rows?: number;
  placeholder?: string;
  onChange: (e: React.SyntheticEvent) => void;
};

const Textarea = ({ rows = 7, placeholder = '', onChange }: TextareaProps) => (
  <StyledTextarea rows={rows} placeholder={placeholder} onChange={onChange} />
);

export default Textarea;
